<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap8VariablesConstantsAndBasicTypes"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 8: Variables, constants and basic types" image-name="variables-consts.jpg" image-alt="Variables, constants and basic types"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a variable? Why do we need them?</p></li>
<li><p>What is a type?</p></li>
<li><p>How to create a variable?</p></li>
<li><p>How to assign a value to a variable?</p></li>
<li><p>How to use variables?</p></li>
<li><p>What is a constant? What are the differences between a constant and a variable?</p></li>
<li><p>How to define a constant?</p></li>
<li><p>How to use constants?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Variable</p></li>
<li><p>Constant</p></li>
<li><p>Type</p></li>
<li><p>Untyped constants</p></li>
</ul>
<div id="a-variable-is-a-space-in-memory" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> A variable is a space in memory <a href="#a-variable-is-a-space-in-memory"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A variable is a space in the computer memory that can contain a changeable piece of data. The term “variable” comes from the Latin word “variabilis” which means “changeable”. In programs, we can create variables that will allow you to store information for later use.</p>
<p>For instance, we want to keep track of the number of guests in a hotel. The number of guests is a number that will vary. We can create a variable to store this information (see <a href="#fig:A-variable-is-space-memory" data-reference-type="ref" data-reference="fig:A-variable-is-space-memory">1</a>).</p>
<figure>
<b-img :src="require('@/assets/images/what_is_a_variable.png')" alt="A variable is a space in computer memory[fig:A-variable-is-space-memory]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">A variable is a space in computer memory<span id="fig:A-variable-is-space-memory" label="fig:A-variable-is-space-memory">[fig:A-variable-is-space-memory]</span></figcaption>
</figure>
<div id="where-are-variables-stored" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Where are variables stored? <a href="#where-are-variables-stored"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We talked previously about ROM, RAM, and auxiliary memory. Where is a Go variable stored? The answer is short; you cannot choose where it will be held. This is the responsibility of the compiler, not yours!</p>
<div id="variable-identifier-name" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Variable identifier (name) <a href="#variable-identifier-name"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In most programming language (and also in Go), when we create a variable we associate it to an <strong>identifier</strong>. The identifier is the “name” of the variable. Giving identifiers to variables allow us to use them quickly in our program. An identifier is composed of letters and digits. The identifier of the variable will be used inside the program to designate the value that is stored inside. The identifier must be short and descriptive.</p>
<p>To create identifiers, programmers can be creative. But they must follow those simple rules :</p>
<ol type="1">
<li><p>Identifiers are composed of</p>
<ol type="1">
<li><p>Unicode letters<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a></p>
<ol type="1">
<li>For example, A, a, B, b, Ô ...</li>
</ol></li>
<li><p>Unicode digits<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a></p>
<ol type="1">
<li>For example : 0,9,3,.…</li>
</ol></li>
</ol></li>
<li><p>The identifier must start with a letter or with the underscore character.“_”</p></li>
<li><p>Some identifiers cannot be used because they are <strong>reserved</strong> by the language</p>
<ol type="1">
<li><strong>Reserved words are</strong> : break, default, func, interface, select, case, defer, go, map, struct, chan, else, goto, package, switch, const, fallthrough, if, range, type, continue, for, import, return, var</li>
</ol></li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >numberOfGuests</code></pre>
<p>is a valid identifier for a variable.</p>
<pre v-highlightjs><code class="go" v-pre >113Guests</code></pre>
<p>is <strong>not valid</strong> because it starts with a digit.</p>
<div id="basic-types" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Basic Types <a href="#basic-types"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We can store information into a variable. The term information is vague; we have to be more precise. Do we need to store digits (1, 2000, 3), floating-point numbers (2.45665), a text (“Room 112 non-smoking”)? A variable has a set of permissible values that we can assign to it. This set is the <strong>type</strong> of the variable. Types have names.</p>
<p>The Go language predeclares a set of basic types that you can use right away into your programs. You can also define your types (we will see it later). For the moment, we will focus only on the most used types :</p>
<ul>
<li><p>Character strings :</p>
<ul>
<li><p><u>Type Name</u>: string</p></li>
<li><p><u>Ex</u> : “management office”, “room 265”,...</p></li>
</ul></li>
<li><p>Unsigned integers</p>
<ul>
<li><p><u>Type names</u>: uint, uint8, uint16, uint32, uint64</p></li>
<li><p><u>Ex</u> : 2445, 676, 0, 1,...</p></li>
</ul></li>
<li><p>Integers</p>
<ul>
<li><p><u>Type Names</u>: int, int8, int16, int32, int64</p></li>
<li><p><u>Ex</u> : -1245, 65, 78,...</p></li>
</ul></li>
<li><p>Booleans</p>
<ul>
<li><p><u>Type Name</u>: bool</p></li>
<li><p><u>Ex</u> : true, false</p></li>
</ul></li>
<li><p>Floating point numbers</p>
<ul>
<li><p><u>Type Name</u>: float32, float64</p></li>
<li><p>Ex : 12.67</p></li>
</ul></li>
</ul>
<div id="about-the-numbers-8-16-32-and-64" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> about the numbers 8, 16, 32 and 64 <a href="#about-the-numbers-8-16-32-and-64"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You may have noticed that we have five flavors of integers: int, int8, int16, int32, int64. The same is happening for unsigned integers. We have uint, uint8, uint16, uint32 and uint64. The choice is more limited for the floating-point numbers: we can use float32 or float64.</p>
<p>If you want to store a number that does not have a sign, you can use unsigned integers types. Those types come in 5 flavors:</p>
<ul>
<li><p>uint8</p></li>
<li><p>unit16</p></li>
<li><p>uint32</p></li>
<li><p>uint64</p></li>
<li><p>uint</p></li>
</ul>
<p>Except for the last one, each one has a number appended to it. The number corresponds to the number of bits of memory allocated to store it.</p>
<p>If you have read the first section, you know that :</p>
<ul>
<li><p>with 8 bits of memory, we can store the decimal numbers from 0 to <span class="math inline">2^{7}+2^{6}+...+2^{0}=255</span>.</p></li>
<li><p>with 16 bits (2 bytes) we can store numbers from 0 to <span class="math inline">2^{15}+2^{14}+...+2^{0}=65.535</span></p></li>
<li><p>with 32 bits (4 bytes) we can store numbers from 0 to <span class="math inline">2^{31}+2^{30}+...+2^{0}=4.294.967.295</span></p></li>
<li><p>with 64 bits (8 bytes) we can store numbers from 0 to <span class="math inline">2^{63}+2^{62}+...+2^{0}=18.446.744.073.709.551.615</span></p></li>
</ul>
<p>You can note that the maximum decimal value of 64 bits is very high. Keep that in mind! If you need to store a value that does not exceed 255 use uint8 instead of uint64. Otherwise, you will waste storage (because you will only use 8 bits of the 64 bits allocated in memory !)</p>
<p>The last type is <strong>uint</strong>. If you use this type in your program, the memory allocated for your unsigned integer will be <strong>at least</strong> 32 bits. It will depend on the system that will run the program. If it’s a 32-bit system, it will be equivalent to uint32. If the system is 64 bits, then the storage capacity of <strong>uint</strong> it will be identical to the one of <strong>uint64.</strong> (to better understand the difference between 32 bits and 64 bits, you can take a look at the previous chapter)</p>
<div id="variable-declaration" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Variable declaration <a href="#variable-declaration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>If you want to use a variable in your program, you need to declare it before.</p>
<div id="the-three-actions-performed-when-a-variable-is-declared" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> The three actions performed when a variable is declared <a href="#the-three-actions-performed-when-a-variable-is-declared"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you declare a variable, it will :</p>
<ol type="1">
<li><p>Bind an <strong>identifier</strong> to the variable</p></li>
<li><p>Bind a <strong>type</strong> to the variable</p></li>
<li><p><strong>Initialize</strong> the variable value to the <strong>default value</strong> of the <strong>type</strong></p></li>
</ol>
<p>If you are used to programming the first two develop the first two actions are common. But the third one is not. <strong>Go initialize the value for you to the default of its type</strong>. Uninitialized variables do not exist in Go.</p>
<div id="variable-declaration-without-an-initializer" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Variable declaration without an initializer <a href="#variable-declaration-without-an-initializer"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/variable_initialization.png')" alt="Variable declation syntax[fig:Variable-declation-syntax]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Variable declation syntax<span id="fig:Variable-declation-syntax" label="fig:Variable-declation-syntax">[fig:Variable-declation-syntax]</span></figcaption>
</figure>
<p>In the figure <a href="#fig:Variable-declation-syntax" data-reference-type="ref" data-reference="fig:Variable-declation-syntax">2</a> you can see how to declare variables. In the first example, we declare one variable of type <strong>int</strong> named <strong>roomNumber.</strong> In the second one, we declare two variables in the same line : <strong>roomNumber</strong> and <strong>floorNumber</strong>. They are of type <strong>int</strong>. Their value will be 0 (which is the zero value of the type int).</p>
<pre v-highlightjs><code class="go" v-pre >// variables-constants-and-basic-types/declaration-without-initializer/main.go
package main

import &quot;fmt&quot;

func main() {
    var roomNumber, floorNumber int
    fmt.Println(roomNumber, floorNumber)

    var password string
    fmt.Println(password)
}</code></pre>
<p>This program outputs :</p>
<pre v-highlightjs><code class="go" v-pre >0 0</code></pre>
<p>The variable <strong>password</strong> of type string is initialized with the zero value of the type string, which is an empty string "". The variables roomNumber and floorNumber are initialized to the zero value of type int, which is 0.</p>
<p>The first line of the program output is the result of <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println(password)</code></span>. The second line is the result of <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println(roomNumber,floorNumber)</code></span>.</p>
<div id="variable-declaration-with-an-initializer" class="anchor"></div>
<h2 data-number="7.3"><span class="header-section-number">7.3</span> Variable declaration with an initializer <a href="#variable-declaration-with-an-initializer"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/variable_decl_with_initialization.png')" alt="Variable declation with initialization syntax[fig:Variable-declation-with-initializer]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Variable declation with initialization syntax<span id="fig:Variable-declation-with-initializer" label="fig:Variable-declation-with-initializer">[fig:Variable-declation-with-initializer]</span></figcaption>
</figure>
<p>You can also declare a variable and initialize its value directly. The possible syntax is described in the figure <a href="#fig:Variable-declation-with-initializer" data-reference-type="ref" data-reference="fig:Variable-declation-with-initializer">3</a>. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// variables-constants-and-basic-types/declaration-variant/main.go
package main

import &quot;fmt&quot;

func main() {
    var roomNumber, floorNumber int = 154, 3
    fmt.Println(roomNumber, floorNumber)

    var password = &quot;notSecured&quot;
    fmt.Println(password)
}</code></pre>
<p>In the <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function, the first statement declares two variables roomNumber and floorNumber. They are of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> and initialized with the values 154 and 3. Then the program will print those variables.</p>
<p>There is an expression or a list of expressions at the equal sign’s left. We will cover expressions in detail in another section.</p>
<p>Then we define the variable <span v-highlightjs><code class="go" v-pre style="display: inline">password</code></span>. We initialize it with the value <span v-highlightjs><code class="go" v-pre style="display: inline">"notSecured"</code></span>. Note here that the type is not written. Go will give the variable the type of the initialization value. Here the type of <span v-highlightjs><code class="go" v-pre style="display: inline">"notSecured"</code></span> is a string; thus, the type of the variable <span v-highlightjs><code class="go" v-pre style="display: inline">password</code></span> is a string.</p>
<div id="short-variable-declaration" class="anchor"></div>
<h2 data-number="7.4"><span class="header-section-number">7.4</span> Short variable declaration <a href="#short-variable-declaration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/short_variable_declaration.png')" alt="Short variable declaration[fig:Short-variable-declaration]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Short variable declaration<span id="fig:Short-variable-declaration" label="fig:Short-variable-declaration">[fig:Short-variable-declaration]</span></figcaption>
</figure>
<p>The short syntax eliminates the <strong>var</strong> keyword. The <span v-highlightjs><code class="go" v-pre style="display: inline">=</code></span> sign is transformed into <span v-highlightjs><code class="go" v-pre style="display: inline">:=</code></span><strong>.</strong>You can also use this syntax to define multiple variables at once.</p>
<pre v-highlightjs><code class="go" v-pre >roomNumber := 154</code></pre>
<p>The type is not written explicitly. The compiler will infer it from the expression (or the expression list).</p>
<p>Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >// variables-constants-and-basic-types/short-declaration/main.go
package main

import &quot;fmt&quot;

func main() {
    roomNumber, floorNumber := 154, 3
    fmt.Println(roomNumber, floorNumber)
}</code></pre>
<ul>
<li>Warning : short variable declaration cannot be used outside functions !</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// will not compile
package main

vatRat := 20

func main(){

}</code></pre>
<ul>
<li>Warning : you cannot use the value <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span> for a short variable declaration. The compiler cannot infer the type of your variable.</li>
</ul>
<div id="what-is-a-constant" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> What is a constant? <a href="#what-is-a-constant"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Constant comes from the Latin word “<strong>constare</strong>” which means<u>"standing firm</u>". A constant is a value in your program that will stand firm, that will not change during the execution. A variable can change during the runtime; a constant will not change; it will stay constant.</p>
<p>For instance, we can store in a constant :</p>
<ul>
<li><p>The version of our program. For instance : “1.3.2”. This value will stay stable during program runtime. We will change this value when we compile another version of our program.</p></li>
<li><p>The build time of the program.</p></li>
<li><p>An email template (if not configurable by our application).</p></li>
<li><p>An error message.</p></li>
</ul>
<p>In summary, use a constant when you are sure that you will never need to change a value during program execution. We say that a constant is <strong>immutable</strong>. Constants come in two flavors: typed and untyped.</p>
<div id="typed-constants" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Typed constants <a href="#typed-constants"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is a typed constant :</p>
<pre v-highlightjs><code class="go" v-pre >const version string = &quot;1.3.2&quot;</code></pre>
<p>The keyword const signal to the compiler that we will define a <strong>const</strong>ant. After the const keyword, the identifier of the constant is set. In our example, the identifier is “version”. The type is explicitly defined (here <strong>string</strong>) and also the value of the constant (in the form of an expression).</p>
<figure>
<b-img :src="require('@/assets/images/constant_declaration.png')" alt="Typed constant declaration"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Typed constant declaration</figcaption>
</figure>
<div id="untyped-constants" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Untyped constants <a href="#untyped-constants"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is an <strong>un</strong>typed constant :</p>
<pre v-highlightjs><code class="go" v-pre >const version = &quot;1.3.2&quot;</code></pre>
<p>An untyped constant :</p>
<ul>
<li><p>has no type</p></li>
<li><p>has a default type</p></li>
<li><p>has no limits</p></li>
</ul>
<div id="an-untyped-constant-has-no-type..." class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> An untyped constant has no type... <a href="#an-untyped-constant-has-no-type..."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s take an example to demonstrate the first point (an untyped constant has no type)</p>
<pre v-highlightjs><code class="go" v-pre >// variables-constants-and-basic-types/untyped-const/main.go
package main

import &quot;fmt&quot;

func main() {
    const occupancyLimit = 12

    var occupancyLimit1 uint8
    var occupancyLimit2 int64
    var occupancyLimit3 float32

    // assign our untyped const to an uint8 variable
    occupancyLimit1 = occupancyLimit
    // assign our untyped const to an int64 variable
    occupancyLimit2 = occupancyLimit
    // assign our untyped const to an float32 variable
    occupancyLimit3 = occupancyLimit

    fmt.Println(occupancyLimit1, occupancyLimit2, occupancyLimit3)
}</code></pre>
<p>In this program, we begin by defining an untyped constant. Its name is <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit</code></span> and its value is 12. At this point in the program, the constant has no particular type. Except it has been set to an integer value.</p>
<p>Then we define 3 variables : <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit1</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit2</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit2</code></span> (types of those variables are uint8, int64, float32).</p>
<p>Then we assign to those variables the value of <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit</code></span><strong>.</strong> Our program compiles. The value of our constant can be put into variables of different types!</p>
<div id="but-a-default-type-when-needed" class="anchor"></div>
<h2 data-number="10.2"><span class="header-section-number">10.2</span> ... but a default type when needed <a href="#but-a-default-type-when-needed"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To understand the notion of default type, let’s take another example</p>
<pre v-highlightjs><code class="go" v-pre >// variables-constants-and-basic-types/default-type/main.go
package main

import &quot;fmt&quot;

func main() {
    const occupancyLimit = 12

    var occupancyLimit4 string

    occupancyLimit4 = occupancyLimit

    fmt.Println(occupancyLimit4)
}</code></pre>
<p>In this program, we define a constant <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit</code></span> which has a value of 12 (an integer). We define a variable <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit4</code></span> with a string type. Then we try to assign to <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit4</code></span> the value of our constant.</p>
<p>We try to convert an integer to a string. Will this program compile? The answer is no! The compilation error is :</p>
<pre v-highlightjs><code class="go" v-pre > ./main.go:10:19: cannot use occupancyLimit (type int) as type string in assignment</code></pre>
<p>An untyped constant has a default type that is defined by the value assigned to it at compilation. In our example, <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit</code></span> has a default type of <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> . An <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> cannot be assigned to a <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span> variable.</p>
<p>Untyped constants default types are :</p>
<ul>
<li><p>bool (for any boolean value)</p></li>
<li><p>rune (for any rune value)</p></li>
<li><p>int (for any integer value)</p></li>
<li><p>float64 (for any floating-point value)</p></li>
<li><p>complex128 (for any complex value)</p></li>
<li><p>string (for any string value)</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// variables-constants-and-basic-types/untyped-default/main.go
package main

func main() {

    // default type is bool
    const isOpen = true
    // default type is rune (alias for int32)
    const MyRune = &#39;r&#39;
    // default type is int
    const occupancyLimit = 12
    // default type is float64
    const vatRate = 29.87
    // default type is complex128
    const complexNumber = 1 + 2i
    // default type is string
    const hotelName = &quot;Gopher Hotel&quot;

}</code></pre>
<div id="an-untyped-constant-has-no-limit" class="anchor"></div>
<h2 data-number="10.3"><span class="header-section-number">10.3</span> An untyped constant has no limit <a href="#an-untyped-constant-has-no-limit"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>An untyped constant has no type and a default type when needed. The value of an untyped constant can overflow its default type. Such a constant has no type; consequently, it does not depend on any type restriction. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// variables-constants-and-basic-types/untyped-no-limit/main.go
package main

func main() {
    // maximum value of an int is 9223372036854775807
    // 9223372036854775808 (max + 1 ) overflows int
    const profit = 9223372036854775808
    // the program compiles
}</code></pre>
<p>In this program, we create an untyped constant named profit. It’s value is <span class="math inline">9223372036854775808</span> which overflows the maximum value allowed for an int (<span v-highlightjs><code class="go" v-pre style="display: inline">int64</code></span> on my 64-bits machine) : <span class="math inline">9223372036854775807</span>. This program compiles perfectly. But when we try to assign this constant value to a typed variable, the program will not compile. Let’s take an example to demonstrate it :</p>
<pre v-highlightjs><code class="go" v-pre >// variables-constants-and-basic-types/untyped-no-limit-2/main.go
package main

import &quot;fmt&quot;

func main() {
    // maximum value of an int is 9223372036854775807
    // 9223372036854775808 (max + 1 ) overflows int
    const profit = 9223372036854775808
    var profit2 int64 = profit
    fmt.Println(profit2)
}</code></pre>
<p>This program defines a variable <span v-highlightjs><code class="go" v-pre style="display: inline">profit2</code></span> which is of type <span v-highlightjs><code class="go" v-pre style="display: inline">int64</code></span>. We then try to assign to <span v-highlightjs><code class="go" v-pre style="display: inline">profit2</code></span> the value of the untyped constant <span v-highlightjs><code class="go" v-pre style="display: inline">profit</code></span>.</p>
<p>Let’s try to compile the program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
# command-line-arguments
./main.go:9:7: constant 9223372036854775808 overflows int64</code></pre>
<p>We get a compilation error, and that’s fine. What we are trying to do is illegal.</p>
<div id="why-using-constants" class="anchor"></div>
<h2 data-number="10.4"><span class="header-section-number">10.4</span> Why using constants? <a href="#why-using-constants"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>You improve the readability of your programs</p>
<ul>
<li>The constant identifier, if well chosen, will give more information to the reader than the raw value</li>
</ul></li>
</ul>
<p>Compare this :</p>
<pre v-highlightjs><code class="go" v-pre >loc, err := time.LoadLocation(UKTimezoneName)
if err != nil {
  return nil, err
}</code></pre>
<p>To this :</p>
<pre v-highlightjs><code class="go" v-pre >loc, err := time.LoadLocation(&quot;Europe/London&quot;)
if err != nil {
  return nil, err
}</code></pre>
<p>We use the constant <span v-highlightjs><code class="go" v-pre style="display: inline">UKTimezoneName</code></span> instead of the raw value <span v-highlightjs><code class="go" v-pre style="display: inline">"Europe/London"</code></span>. We hide the complexity of the timezone string to the reader. In addition to that, the reader will understand what our intention was; we want to load the UK’s location.</p>
<ul>
<li><p>You allow potential reuse of the value (by another program or in your own program)</p></li>
<li><p>The compiler might improve the machine code produced. You say to the compiler that this value will never change; if the compiler is smart (and it is), it will ingeniously use it.</p></li>
</ul>
<div id="choosing-your-identifiers-variable-names-constant-names" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Choosing your identifiers (variable names, constant names) <a href="#choosing-your-identifiers-variable-names-constant-names"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Naming variables and constants is not an easy task. When you choose a name, you have to be sure that the selected name gives the right amount of information about its designation. The other developers that work on the same project will be thankful if you choose a good identifier name because reading the code will be easier. When we say “convey the right amount of information” the word “right” is vague. There are no scientific rules for naming program constructs. Even though there are no scientific rules, I can give you some advice shared among our community.</p>
<ol type="1">
<li><p>Avoid names with one letter: it conveys too little information about what is stored.</p>
<ol type="1">
<li>The exception is for <strong>counters</strong> which are often named <span v-highlightjs><code class="go" v-pre style="display: inline">k</code></span><strong>,</strong> <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">j</code></span> (inside loops, that we will cover later)</li>
</ol></li>
<li><p>Use camel case: this is a well-established convention in the go community.</p>
<ol type="1">
<li>Prefer <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLimit</code></span> to <span v-highlightjs><code class="go" v-pre style="display: inline">occupancy_limit</code></span><a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a> or <span v-highlightjs><code class="go" v-pre style="display: inline">occupancy-limit</code></span><a href="#fn4" class="footnote-ref" id="fnref4" role="doc-noteref"><sup>4</sup></a></li>
</ol></li>
<li><p>No more than two words</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">profitValue</code></span> is good, <span v-highlightjs><code class="go" v-pre style="display: inline">profitValueBeforeTaxMinusOperationalCosts</code></span> is too long</li>
</ol></li>
<li><p>Avoid mentioning the type in the name</p>
<ol type="1">
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">descriptionString</code></span> is bad, <span v-highlightjs><code class="go" v-pre style="display: inline">description</code></span> is better</p></li>
<li><p>Go is already statically typed; you do not need to give the type information to the reader. (In some loosely typed language, it’s sometimes necessary to avoid confusion)</p></li>
</ol></li>
</ol>
<div id="practical-application" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Practical Application <a href="#practical-application"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="mission" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Mission <a href="#mission"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="program" class="anchor"></div>
<h3 data-number="12.1.1"><span class="header-section-number">12.1.1</span> Program <a href="#program"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Write a program that will :</p>
<ul>
<li><p>Create a string constant named <span v-highlightjs><code class="go" v-pre style="display: inline">hotelName</code></span> and with value <span v-highlightjs><code class="go" v-pre style="display: inline">"Gopher Hotel"</code></span></p></li>
<li><p>Create two untyped constants that will contain respectively 24.806078 and -78.243027. The names of those two constants are <span v-highlightjs><code class="go" v-pre style="display: inline">longitude</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">latitude</code></span>. (somewhere in the Bahamas)</p></li>
<li><p>Create a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> named <span v-highlightjs><code class="go" v-pre style="display: inline">occupancy</code></span> which is initialized with the value 12.</p></li>
<li><p>Print the <span v-highlightjs><code class="go" v-pre style="display: inline">hotelName</code></span> the <span v-highlightjs><code class="go" v-pre style="display: inline">longitude</code></span>, the <span v-highlightjs><code class="go" v-pre style="display: inline">latitude</code></span> and the <span v-highlightjs><code class="go" v-pre style="display: inline">occupancy</code></span> on a new line</p></li>
</ul>
<div id="bonus-questions" class="anchor"></div>
<h3 data-number="12.1.2"><span class="header-section-number">12.1.2</span> Bonus questions <a href="#bonus-questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ol type="1">
<li><p>What is the default type of <span v-highlightjs><code class="go" v-pre style="display: inline">longitude</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">latitude</code></span>?</p></li>
<li><p>What is the type of <span v-highlightjs><code class="go" v-pre style="display: inline">longitude</code></span>?</p></li>
<li><p>The variable <span v-highlightjs><code class="go" v-pre style="display: inline">occupancy</code></span> is of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>. Is it a 64, 32, or 8-bits integer ?</p></li>
</ol>
<div id="solution-explained" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> Solution explained <a href="#solution-explained"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="program-1" class="anchor"></div>
<h3 data-number="12.2.1"><span class="header-section-number">12.2.1</span> Program <a href="#program-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// variables-constants-and-basic-types/mission-solution/main.go
package main

import &quot;fmt&quot;

func main() {
    const hotelName string = &quot;Gopher Hotel&quot;
    const longitude = 24.806078
    const latitude = -78.243027
    var occupancy int = 12
    fmt.Println(hotelName, longitude, latitude)
    fmt.Println(occupancy)
}</code></pre>
<p>We begin with the definition of the typed constant <span v-highlightjs><code class="go" v-pre style="display: inline">hotelName</code></span> and the definition of <span v-highlightjs><code class="go" v-pre style="display: inline">longitude</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">latitude</code></span> (which are untyped). Then we define the variable <span v-highlightjs><code class="go" v-pre style="display: inline">occupancy</code></span> (type: <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>) and set its value to 12. Note that another syntax is possible :</p>
<ul>
<li>A short variable declaration</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre > occupancy := 12</code></pre>
<ul>
<li>The type can be omitted in the standard declaration :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre > var occupancy = 12</code></pre>
<ul>
<li>We can make the declaration of the variable and the assignment in two steps</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >var occupancy int   
occupancy = 12</code></pre>
<div id="bonus-questions-answers" class="anchor"></div>
<h3 data-number="12.2.2"><span class="header-section-number">12.2.2</span> Bonus questions answers <a href="#bonus-questions-answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ol type="1">
<li><p>What is the default type of <span v-highlightjs><code class="go" v-pre style="display: inline">longitude</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">latitude</code></span>?</p>
<ol type="1">
<li>float64</li>
</ol></li>
<li><p>What is the type of <span v-highlightjs><code class="go" v-pre style="display: inline">longitude</code></span>?</p>
<ol type="1">
<li>It has no type, and it’s the same for <span v-highlightjs><code class="go" v-pre style="display: inline">latitude</code></span><strong>.</strong></li>
</ol></li>
<li><p>The variable <span v-highlightjs><code class="go" v-pre style="display: inline">occupancy</code></span> is of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>. Is it a 64, 32, or 8-bits integer ?</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> is a type that has an implementation-specific size on a 32-bits computer it will be a 32 bits integer (int32); on a 64-bits computer, it will be a 64 bits integer (int64)</li>
</ol></li>
</ol>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is an identifier?</p></li>
<li><p>By which type of character should an identifier begin?</p></li>
<li><p>What is the type of a variable?</p></li>
<li><p>What is a byte?</p></li>
<li><p>When you declare a type bool variable, what is its value (right after initialization)?</p></li>
<li><p>What are the two main characteristics of an untyped constant?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is an identifier?</p>
<ol type="1">
<li>An identifier is a set of characters composed of letters and digits.</li>
</ol></li>
<li><p>By which type of character should an identifier begin?</p>
<ol type="1">
<li>A letter or an underscore.</li>
</ol></li>
<li><p>What is the type of a variable?</p>
<ol type="1">
<li>The type of a variable is the set of permissible variable values.</li>
</ol></li>
<li><p>What is a byte?</p>
<ol type="1">
<li>A byte is composed of 8 binary digits</li>
</ol></li>
<li><p>When you declare a variable of type bool. What is its value (right after initialization)?</p>
<ol type="1">
<li>false. When you declare a variable, it is initialized to its type’s default value.</li>
</ol></li>
<li><p>What are the three characteristics of an untyped constant?</p>
<ol type="1">
<li><p>It has no type</p></li>
<li><p>It has a default type</p></li>
<li><p>It has no limit</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>The name of a variable or constant is called an identifier</p></li>
<li><p>Identifiers are generally written using camelCase</p></li>
<li><p>Variables and constants allow you to save a value in memory</p></li>
<li><p>Constants are immutable, meaning that we cannot change their value during program execution</p></li>
<li><p>Variables have a type, which defines the set of values they can hold</p></li>
<li><p>When you create a variable, its value is initialized with the zero value of its type</p>
<ul>
<li><p>This one is super important...</p></li>
<li><p>It can be a source of mistakes.</p></li>
<li><p>There are NO uinitialized variables in Go.</p></li>
</ul></li>
<li><p>There are two flavors of constants</p>
<ul>
<li><p>Typed</p></li>
<li><p>Untyped</p>
<ul>
<li>Have no type but a default one and can overflow their default type.</li>
</ul></li>
</ul></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>In the Go specification, a Unicode letter is any character in the list Lu, Ll, Lt, Lm, or Lo from the Unicode specification 8.0<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>In the Go specification, a Unicode digit is any character in the list Nd from the Unicode specification 8.0<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>Snake case<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn4" role="doc-endnote"><p>Kebab case<a href="#fnref4" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap8VariablesConstantsAndBasicTypes"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap7HexadecimalOctalAsciiUtf8UnicodeRunes'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Hexadecimal, octal, ASCII, UTF8, Unicode, Runes</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap9ControlStatements'}">
									<p><u><small>Next</small></u></p>
									<p><small>Control Statements</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Variables, constants and basic types - Practical Go Lessons"
const description = "A variable is a space in the computer memory that can contain a changeable piece of data. The term “variable” comes from the Latin word “variabilis” which means “changeable”. In programs, we can create variables that will allow you to store information for later use."

export default {
  name: "Chap8VariablesConstantsAndBasicTypes",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
